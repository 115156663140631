<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $getByLang(body.name) }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white;
      padding-top: 36px;
padding-bottom: 50px;"
    >
      <div class="container " v-html="$getByLang(body.body)"></div>
    </section>


     <!-- 3 for-caubles -->
     <div v-if="body.slug == 'gifts'">
      <!--SECTION START-->
      <section style="background-color: white;;">
        <div class="container ">
         
         

           
           
            <div>
              <h3>{{ $t('Price') }}:</h3>
              <div style="padding-left: 20px">
                <ul>
                  <li v-for="itm of prices3" :key="itm.id">
                    {{ itm.minutes }} minutes = {{ itm.price }}€
                    {{
                      itm.recommended == 1 ? '(' + $t('recommended') + ')' : ''
                    }}
                    <p style="font-size: 10px;color: #666;line-height: 15px;">
                      {{ itm.services.map((el) => $getByLang(el.name)) }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>

        </div>
      </section>
      <p class="text-center">
        <!-- <router-link
          :to="$getLink() + 'booking/' + slug3"
          class="btn btn-primary"
          style="height: 60px;font-size: 25px;"
          >{{ $t('Appointment') }}</router-link
        > -->
        <a
        href="https://salonkee.lu/salon/egypta-massage-bascharage?lang=fr"
        target="_blank"
        class="btn btn-primary"
        style="height: 60px; font-size: 25px"
      >
        {{ $t("Appointment") }}
      </a>
      </p>
      <p class="text-center">
        <router-link
          :to="$getLink() + 'gift/' + slug3"
          class="btn btn-danger"
          style="height: 60px; font-size: 25px;"
          >{{ $t('Gift Voucher') }}</router-link
        >
      </p>
      <!--SECTION END-->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        name: null,
        body: null,
      },

      baseUploadURL: this.$baseUploadURL,
      name3: null,
      body3: null,
      image3: null,
      id3: null,
      slug3: null,
      prices3: null,
    };
  },

  created() {
    const slug = this.$route.params.slug;
    if (slug) {
      this.$http.get(`page/getById/${slug}`).then(
        (res) => {
          if (res.data) {
            this.body = res.data;
            if(slug == 'gifts'){

              this.$http.get(`massage/getById/Massage_Carte`).then(
                  (res2) => {
                    if (res2.data) {
                      this.name3 = res2.data.name;
                      this.body3 = res2.data.body;
                      this.image3 = res2.data.image;
                      this.id3 = res2.data.id;
                      this.slug3 = res2.data.slug;
                      this.prices3 = res2.data.prices;
                    }
                  },
                  (err) => {
                    console.log(err);
                  },
                );
            }
          }
        },
        (err) => {
          console.log(err);
        },
      );
    }
  },
};
</script>

<style></style>
